<template>
  <div class="tc-evaluate_entry">
    <div class="form">
      <el-form ref="form" :model="form" label-width="130px" size="mini">
        <el-form-item label="测评用户：">
          <el-radio-group v-model="form.resource">
            <el-radio label="0">查询用户</el-radio>
            <el-radio label="1">添加用户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择部门：">
          <el-select v-model="form.department_id" @change="upUser">
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              >{{ item.name }}</el-option
            >
          </el-select>
        </el-form-item>
        <div class="query" v-if="form.resource == 0">
          <el-form-item label="选择用户：">
            <!-- <span>请先选择部门</span> -->
            <el-select v-model="form.uId" filterable>
              <el-option
                v-for="item in userInfo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="addUser" v-if="form.resource == 1">
          <el-form-item label="用户编号：">
            <el-input v-model="form.account"></el-input>
          </el-form-item>
          <el-form-item label="姓名：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="密码：">
            <el-input v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="学历：">
            <el-select v-model="form.culture">
              <el-option
                :label="item"
                :value="item"
                v-for="(item, key) in culture"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="性别：">
            <el-radio-group v-model="form.sex">
              <el-radio label="0">男</el-radio>
              <el-radio label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="出生日期(公历)：">
            <el-date-picker
              v-model="form.value1"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item> -->
          <el-form-item label="年龄" prop="ages">
            <el-input
              type="number"
              v-model="form.ages"
              maxlength="16"
              :min="0"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="测评时间：">
          <el-date-picker
            v-model="form.value2"
            type="date"
            placeholder="选择日期"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="选择量表：">
          <el-select v-model="form.measure" @change="loadAnswer">
            <el-option
              :label="item.measure_title"
              :value="item.id"
              v-for="(item, key) in scaleList"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <el-row>
      <h3>测评结果:</h3>
      <table>
        <el-row v-for="(item, key) in subject" :key="key">
          <el-col class="an-hover">
            <tr class="an-title">
              {{
                item.question
              }}
            </tr>
            <tr v-for="(item2, key2) in item.options" :key="key2">
              <el-radio
                v-model="answerData[key]"
                :label="key2 + `-` + item.id"
                >{{ item2 }}</el-radio
              >
            </tr>
          </el-col>
        </el-row>
      </table>
    </el-row>
    <div class="confirm-btn" @click="onSubmit">
      <img src="../../../assets/images/confirm.png" alt />
    </div>
  </div>
</template>
<script>
import { loadDepartmentData } from "@/api/comm.js";
import { adminResultsCalculated } from "@/api/information.js";
import {
  getMeasureClassifyList,
  loadUserData,
  getMeasureInfobyid,
  getMeasureByClassify,
} from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      form: {
        resource: "0",
        value1: "",
        value2: "",
        department_id: "",
        uId: "",
        account: "",
        name: "",
        age: "",
        sex: "",
        password: "",
        culture: "",
        measure: "",
        ages:"",
      },
      // 部门
      departmentList: [],
      // 报告类型
      evaluateClass: [],
      scaleList: [],
      // 用户数据
      userInfo: [],
      // 答题题目数据
      subject: [],
      answerData: [],
      culture: [
        "文盲",
        "小学",
        "初中",
        "高中",
        "中专",
        "大专",
        "本科",
        "研究生",
        "博士",
        "博士后",
      ],
    };
  },
  created() {
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    // 获取报告类型
    getMeasureClassifyList({ page: 1, size: 99999 }).then((res) => {
      this.evaluateClass = res.data.data;
    });
    // 获取量表名称
    this.loadScaleData();
  },
  methods: {
    onSubmit() {
      // 数据处理
      let data = [];
      let a = ["A", "B", "C", "D", "E", "F", "G", "H"];
      this.answerData.forEach((v, k) => {
        let arr = v.split("-");
        let obj = {
          id: arr[1],
          answer: a[parseInt(arr[0])],
        };
        data.push(obj);
      });
      let p = {
        user_id: this.form.uId,
        measureId: this.form.measure,
        evaluating_time: this.form.value1 / 1000,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: data,
      };

      if (this.form.resource == 1) {
        p = {
          account: this.form.account,
          password: this.form.password,
          department_id: this.form.department_id,
          measureId: this.form.measure,
          name: this.form.name,
          sex: this.form.sex,
          age: this.form.ages,
          ages:this.form.ages,
          culture: this.form.culture,
          evaluating_time: this.form.value2 / 1000,
          systemAccess: {
            accessToken: "md5",
          },
          isSave: true,
          answer: data,
        };
      }
      if(!this.form.ages){
        this.$message({
          showClose: true,
          message: "请填写年龄！",
          type: "success",
        });
        return
      }
      adminResultsCalculated(p).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "录入成功！",
            type: "success",
          });
          this.reload();
        }
      });
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await getMeasureByClassify();
      res.data.forEach((v) => {
        v.status_measure.forEach((v) => {
          this.scaleList.push(v);
        });
      });
    },
    // 加载用户信息
    loadUserInfo() {
      let data = {
        page: 1,
        pagesize: 99,
        department_id: this.form.department_id,
      };
      loadUserData(data).then((res) => {
        res.data.forEach((v) => {
          let obj = {
            id: v.id,
            name: v.real_name,
          };
          this.userInfo.push(obj);
        });
      });
    },
    // 监听部门下拉框，并获取用户数据
    upUser() {
      this.userInfo = [];
      this.loadUserInfo();
    },
    // 答题渲染
    async loadAnswer() {
      let id = this.form.measure;
      let res = await getMeasureInfobyid(id);
      let r = res.data;
      this.subject = r.questions;
      this.answerData = [];
    },
    // 防止用户跳题,提前提交
    inspect() {
      // 题目第一题的 id
      let one = this.subject[0].id;
      // 点击前的 题目ID
      let a = 0;

      // 当前最新选择的 题目 ID
      if (this.answerData.length > 1) {
        if (this.answerData[this.answerData.length - 2] == undefined) {
          this.$alert("请按照题目顺序依次选择，谢谢", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          // 清除最后一个
          this.answerData.pop();
          return;
        } else {
          return;
        }
      } else {
        return;
      }
    },
  },
  watch: {
    answerData: function () {
      this.inspect();
    },
  },
};
</script>

<style lang="less">
.tc-evaluate_entry {
  .form {
    width: 500px;
    .el-input__inner {
      width: 180px;
      padding-right: 20px;
    }
  }
  .confirm-btn {
    margin-top: 20px;
    width: 300px;
    text-align: center;
  }
  .an-title {
    font-weight: 600;
    color: rgb(18, 54, 42);
  }
  .an-hover {
    padding: 10px 10px;
    border-radius: 10px;
    &:hover {
      transition: all 0.5s;
      background: rgb(199, 239, 246);
    }
  }
}
</style>